import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './Cookies.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookie } from '@fortawesome/free-solid-svg-icons';

function Cookies({ showPolicy, handleClosePolicy, handlePolicyClick, showCustomize, handleCustomize, setShowCustomize }) {
    const [visible, setVisible] = useState(true);
    const [iconVisible, setIconVisible] = useState(false);
    const [cookiePreferences, setCookiePreferences] = useState({
        necessary: true, // Sempre selezionato
        experience: false,
        measurement: false,
        marketing: false,
    });


    useEffect(() => {
        const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));

        if (savedPreferences) {
            setCookiePreferences(savedPreferences);
            setVisible(false);
            setIconVisible(true);
        }
    }, []);


    // Salva le preferenze nel localStorage
    const savePreferences = (preferences) => {
        localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    };

    // Chiude il banner e mostra l'icona di riapertura
    const handleClose = () => {
        setVisible(false);
        setIconVisible(true);
    };

    // Mostra il banner quando si clicca sull'icona
    const handleIconClick = () => {
        setVisible(true);
        setIconVisible(false);
    };

    // Accetta tutti i cookie
    const handleAcceptAll = () => {
        const allAccepted = {
            necessary: true,
            experience: false,
            measurement: false,
            marketing: false,
        };
        setCookiePreferences(allAccepted);
        savePreferences(allAccepted);
        handleClose();
    };

    // Rifiuta tutti i cookie (tranne i necessari)
    const handleRejectAll = () => {
        const onlyNecessary = {
            necessary: true,
            experience: false,
            measurement: false,
            marketing: false,
        };
        setCookiePreferences(onlyNecessary);
        savePreferences(onlyNecessary);
        handleClose();
    };

    // Salva le preferenze scelte dall'utente e chiude il banner
    const handleSaveSettings = () => {
        savePreferences(cookiePreferences);
        handleClose();
        setShowCustomize(false);
    };


    return (
        <>
            {visible && !showCustomize && (
                <div className="cookie-banner">
                    <button className="close-button" onClick={handleClose}>
                        X
                    </button>
                    <p>Informativa sintetica/breve.</p>
                    <p><strong>Questo sito web utilizza i cookie</strong></p>
                    <p>
                        In questo sito web sono preselezionati solo cookies necessari/tecnici necessari alla navigazione.
                        Per conoscere nel dettaglio quali cookie utilizziamo leggi la nostra
                        <a href="#" onClick={handlePolicyClick}> informativa cookies (estesa)</a>.
                    </p>
                    <p>
                        Cliccando sulla "X" in alto a destra o sul pulsante "Rifiuta tutti" saranno mantenuti attivi solo i cookies
                        necessari, cioè quelli tecnici che consentono la navigazione nel sito.
                    </p>
                    <p>
                        Cliccando su "Accetta tutti" si autorizza l'uso di tutti i cookies.
                        Cliccando su "Personalizza" si può scegliere in modo libero e consapevole quali cookies attivare o lasciare
                        disattivati.
                    </p>
                    <p>
                        Una volta chiuso il banner è presente un'icona che darà la possibilità di modificare le scelte sui cookie
                        effettuate precedentemente.
                    </p>
                    <div className="cookie-buttons">
                        <button className="accept-button" onClick={handleAcceptAll}>
                            Accetta Tutti
                        </button>
                        <button className="reject-button" onClick={handleRejectAll}>
                            Rifiuta Tutti
                        </button>
                        <button className="customize-button" onClick={handleCustomize}>
                            Personalizza
                        </button>
                    </div>
                </div>
            )}

            {/* Modal per la Policy Estesa */}
            {showPolicy && (
                <div className="policy-modal">
                    <div className="policy-modal-content">
                        <div className='container-policy-x'>
                            <h2>COOKIE POLICY</h2>
                            <button className="close-policy-button" onClick={handleClosePolicy}>
                                X
                            </button>
                        </div>
                        <h3>Informativa sui cookie</h3>
                        <p>
                            Questa pagina fornisce l'informativa completa sull'utilizzo di cookie nel sito web <a href="https://www.merendels.com/">www.merendels.com</a>.
                            Nello specifico informazioni in merito alle tecnologie che consentono a questo sito web di raggiungere gli scopi descritti di seguito.
                            Tali tecnologie permettono al Titolare di raccogliere e salvare informazioni (per esempio tramite l'utilizzo di Cookie)
                            o di utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell'Utente quando quest'ultimo
                            interagisce con questo sito web.
                        </p>
                        <p>
                            Si invita a consultare la <Link to="/privacy-policy" onClick={handleClosePolicy}>Privacy Policy</Link>  per ulteriori informazioni sui trattamenti di dati personali
                            che vengono svolti in conseguenza della navigazione nel presente sito web.
                        </p>
                    </div>
                </div>
            )}


            {showCustomize && (
                <div className="cookie-settings">
                    <h3>Personalizza i tuoi cookie</h3>

                    {/* Sezione per i cookie necessari */}
                    <div className="cookie-option">
                        <span className="cookie-option-label">Necessari</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={cookiePreferences.necessary}
                                disabled
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    {/* Sezione per i cookie di esperienza */}
                    <div className="cookie-option">
                        <span className="cookie-option-label">Esperienza</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={cookiePreferences.experience}
                                onChange={(e) =>
                                    setCookiePreferences((prev) => ({
                                        ...prev,
                                        experience: e.target.checked,
                                    }))
                                }
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    {/* Sezione per i cookie di misurazione */}
                    <div className="cookie-option">
                        <span className="cookie-option-label">Misurazione</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={cookiePreferences.measurement}
                                onChange={(e) =>
                                    setCookiePreferences((prev) => ({
                                        ...prev,
                                        measurement: e.target.checked,
                                    }))
                                }
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    {/* Sezione per i cookie di marketing */}
                    <div className="cookie-option">
                        <span className="cookie-option-label">Marketing</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={cookiePreferences.marketing}
                                onChange={(e) =>
                                    setCookiePreferences((prev) => ({
                                        ...prev,
                                        marketing: e.target.checked,
                                    }))
                                }
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <button className="save-button" onClick={handleSaveSettings}>
                        Salva impostazioni
                    </button>
                </div>
            )}

            {iconVisible && (
                <button className="reopen-icon" onClick={handleIconClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-cookie" viewBox="0 0 16 16">
                        <path d="M6 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m4.5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m-.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                        <path d="M8 0a7.96 7.96 0 0 0-4.075 1.114q-.245.102-.437.28A8 8 0 1 0 8 0m3.25 14.201a1.5 1.5 0 0 0-2.13.71A7 7 0 0 1 8 15a6.97 6.97 0 0 1-3.845-1.15 1.5 1.5 0 1 0-2.005-2.005A6.97 6.97 0 0 1 1 8c0-1.953.8-3.719 2.09-4.989a1.5 1.5 0 1 0 2.469-1.574A7 7 0 0 1 8 1c1.42 0 2.742.423 3.845 1.15a1.5 1.5 0 1 0 2.005 2.005A6.97 6.97 0 0 1 15 8c0 .596-.074 1.174-.214 1.727a1.5 1.5 0 1 0-1.025 2.25 7 7 0 0 1-2.51 2.224Z" />
                    </svg>
                </button>
            )}
        </>
    );
}

export default Cookies;
